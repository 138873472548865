import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './shared/services/guards/admin.guard';
import { AuthGuard } from './shared/services/guards/auth.guard';
import { ManagementGuard } from './shared/services/guards/management.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'organizational',
    loadChildren: () =>
      import('./organizational/organizational.module').then(
        (m) => m.OrganizationalModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, ManagementGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'interview',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./interview/interview.module').then((m) => m.InterviewModule),
  },
  {
    path: 'custom-resume',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./custom-resume/custom-resume.module').then(
        (m) => m.CustomResumeModule
      ),
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

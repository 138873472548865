import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const initSentry = () => {
  const origin = environment.production
    ? 'https://recruiting.pimenta.group'
    : 'https://recruiting.a.pimenta.cloud';
  const sentryEnvironment = environment.production ? 'production' : 'staging';
  Sentry.init({
    dsn: environment.sentry.dsn,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [origin],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: sentryEnvironment,
  });
};
if (environment.production) {
  enableProdMode();
}

initSentry();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import { Component, OnInit } from '@angular/core';
import { ProfessionalService } from './shared/services/professional/professional.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private readonly professionalService: ProfessionalService) {}

  private workingAreaType = `working-area-${environment.environment}`;

  ngOnInit(): void {
    this.getWorkingArea();
  }

  private getWorkingArea(): void {
    this.professionalService.getAreaOfExpertise().subscribe({
      next: ({ data }) => {
        if (data[0]) {
          const { _id, area } = data[0];
          const workingArea = { id: _id, name: area };

          localStorage.setItem(
            this.workingAreaType,
            JSON.stringify(workingArea)
          );
        } else {
          localStorage.removeItem(this.workingAreaType);
        }
      },
      error: () => {
        localStorage.removeItem(this.workingAreaType);
      },
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { TokenService } from '../authentication/token.service';
import {} from '../admin/settings/roles.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements OnDestroy {
  constructor(
    private router: Router,
    private readonly tokenService: TokenService
  ) {}

  private destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  canActivate(): Observable<boolean> {
    return this.tokenService.getAdminPermission().pipe(
      takeUntil(this.destroy$),
      map((response) => {
        if (response) {
          return response;
        }

        return false;
      })
    );
  }
}

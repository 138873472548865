import { Environment } from '../app/shared/interfaces/environment/environment';

// Convert the ENVIRONMENT variable to lower case and check if it's "true"
const envValue = (process.env['ENVIRONMENT'] || '').toLowerCase();
const isProductionEnvironment = envValue === 'true';

// ENVIRONMENT_NAME: Holds the name or identifier of the current runtime environment.
// This value is typically used to distinguish between development, staging, production, etc.
// It is read from the ENVIRONMENT environment variable.
const ENVIRONMENT_NAME = process.env['ENVIRONMENT'];

// Configuration for backend API endpoints
const BASE_API = `${process.env['API_PIMENTA_URL']}/pimenta/api`

const API_BACKEND = {
  pimenta: BASE_API,
  authorizer: `${BASE_API}/auth`,
  secure: `${BASE_API}/secure`,
  oauth: `${BASE_API}/oauth`,
};

// Google service configurations
const GOOGLE = {
  client: process.env['GOOGLE_CLIENT_ID'],
  secret: process.env['GOOGLE_SECRET'],
  api: process.env['GOOGLE_API'],
  placesAutocomplete: process.env['GOOGLE_PLACES_AUTOCOMPLETE_KEY'],
};

// Sentry configuration for error tracking
const SENTRY = { dsn: process.env['SENTRY_DSN'] };

// Mailu email server configuration
const MAILU = { hostname: process.env['MAILU_HOSTNAME'] };

// INTERVIEW_URL: Contains the URL used for interview-related functionalities within the application.
// This is an external service
// The URL is fetched from the INTERVIEW_URL environment variable.
const INTERVIEW_URL = process.env['INTERVIEW_URL'];

// Aggregate all configurations into the environment object
export const environment: Environment = {
  production: isProductionEnvironment,
  environment: ENVIRONMENT_NAME,
  api: API_BACKEND,
  sentry: SENTRY,
  mailu: MAILU,
  interviewUrl: INTERVIEW_URL,
  googleClient: GOOGLE,
};

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ProfessionalItemsModel,
  ProfessionalAreasModel,
  UploadProfessionalJsonModel,
  AreaOfExpertiseModel,
  ProfessionalSkillsModel,
  UpdateProfessionalItemsModel,
  CreateProfessionalItemsModel,
} from '../../interfaces/professional/professional.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalService {
  constructor(private readonly http: HttpClient) {}
  private readonly path = environment.api.secure;

  public listProfessionalItems(
    adminId: string,
    area?: string,
    category?: string
  ): Observable<{ data: ProfessionalItemsModel[] }> {
    let queryParams = new HttpParams();

    if (area) {
      queryParams = queryParams.append('area', area);
    }

    if (category) {
      queryParams = queryParams.append('category', category);
    }

    return this.http
      .get<{ data: ProfessionalItemsModel[] }>(
        this.path + `/admin/${adminId}/work-positions`,
        {
          params: queryParams,
        }
      )
      .pipe(map((response) => response));
  }

  public manageProfessionalItems<T>(
    operationType: string,
    adminId: string,
    areaId?: string,
    categoryId?: string,
    positionId?: string,
    body?: T
  ): Observable<unknown> {
    let url = `${this.path}/admin/${adminId}/work-positions`;

    if (positionId) {
      url += `/${areaId}/${categoryId}/${positionId}`;
    } else if (categoryId) {
      url += `/${areaId}/${categoryId}`;
    } else if (areaId) {
      url += `/${areaId}`;
    }

    let request: Observable<unknown>;

    switch (operationType) {
    case 'creation':
      request = this.http.post<{ data: ProfessionalAreasModel }>(url, body);
      break;
    case 'edition':
      request = this.http.patch<{ data: ProfessionalAreasModel }>(url, body);
      break;
    case 'delete':
      request = this.http.delete<void>(url);
      break;
    }

    return request.pipe(map((response) => response));
  }

  public downloadJSON(data: unknown, filename: string): void {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  public uploadProfessionalJSON(
    body: UploadProfessionalJsonModel,
    adminId: string
  ): Observable<{ data: ProfessionalAreasModel }> {
    const url = `${this.path}/admin/${adminId}/work-positions`;

    return this.http
      .post<{ data: ProfessionalAreasModel }>(url, body)
      .pipe(map((response) => response));
  }

  public getAllProfessionalItems(
    areaId: string
  ): Observable<{ data: ProfessionalAreasModel }> {
    const url = `${this.path}/all-work-positions/${areaId}`;

    return this.http
      .get<{ data: ProfessionalAreasModel }>(url)
      .pipe(map((response) => response));
  }

  public getAreaOfExpertise(): Observable<{ data: AreaOfExpertiseModel[] }> {
    const url = `${this.path}/area-of-expertise`;

    return this.http
      .get<{ data: AreaOfExpertiseModel[] }>(url)
      .pipe(map((response) => response));
  }

  public updateAreaOfExpertise(
    adminId: string,
    areaId: string
  ): Observable<{ data: AreaOfExpertiseModel }> {
    const url = `${this.path}/admin/${adminId}/area-of-expertise/${areaId}`;

    return this.http
      .patch<{ data: AreaOfExpertiseModel }>(url, null)
      .pipe(map((response) => response));
  }

  public listProfessionalSkills(adminId: string,
    areaId: string): Observable<{ data: ProfessionalItemsModel[] }> {
    const url = `${this.path}/admin/${adminId}/skills/${areaId}`;

    return this.http
      .get<{ data: ProfessionalItemsModel[] }>(url)
      .pipe(map((response) => response));
  }

  public createProfessionalSkill(adminId: string,
    areaId: string, body:UploadProfessionalJsonModel | CreateProfessionalItemsModel): Observable<{ data: ProfessionalSkillsModel | ProfessionalSkillsModel[] }> {
    const url = `${this.path}/admin/${adminId}/skills/${areaId}`;

    return this.http
      .post<{ data: ProfessionalSkillsModel | ProfessionalSkillsModel[] }>(url, body)
      .pipe(map((response) => response));
  }

  public updateProfessionalSkill(adminId: string, skillId: string, body: UpdateProfessionalItemsModel): Observable<{ data: ProfessionalSkillsModel }> {
    const url = `${this.path}/admin/${adminId}/skills/${skillId}`;

    return this.http
      .patch<{ data: ProfessionalSkillsModel }>(url, body)
      .pipe(map((response) => response));
  }

  public deleteProfessionalSkill(adminId: string, skillId: string): Observable<void> {
    const url = `${this.path}/admin/${adminId}/skills/${skillId}`;

    return this.http
      .delete<void>(url)
      .pipe(map((response) => response));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { TokenPayload } from '../../interfaces/authentication/token-payload.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private readonly http: HttpClient) {}

  public getOauthToken(id: string): Observable<Record<string, string>> {
    return this.http
      .post<Record<string, string>>(
        `${environment.api.oauth}/interview/user-token`,
        { userId: id }
      )
      .pipe(map((response) => response));
  }

  public getInterviewToken(): string {
    return localStorage.getItem('interview-token');
  }

  public setInterviewToken(token: string): void {
    localStorage.setItem('interview-token', token);
  }

  public getUserById(): string {
    const token: TokenPayload = jwtDecode(this.getInterviewToken());
    return token.data.userId;
  }

  public getUserLogged(): boolean {
    return localStorage.getItem('logged') ? true : false;
  }

  public getAdminPermission(): Observable<boolean> {
    const userId = this.getUserById();
    return this.http
      .get<Record<string, boolean>>(
        `${environment.api.secure}/account/guard/${userId}`
      )
      .pipe(
        map((response) => {
          return response.status;
        })
      );
  }

  public isValidUser(): boolean {
    return !!this.getInterviewToken();
  }
}
